<template>
  <div>
    <div class="select-integration">
      <!-- add integration -->
      <!-- <vs-dropdown vs-trigger-click :disabled="cantAddIntegration">
        <vs-button
          type="flat"
          icon-pack="feather"
          icon="icon-zap"
          :disabled="cantAddIntegration"
          >{{ lang.botMaker.integrations.add[languageSelected] }}</vs-button
        >
        <vs-dropdown-menu>
          <vs-dropdown-item
            v-for="(api, index) in availableApis"
            :value="api.url"
            :key="index"
            @click="addIntegration(api)"
            :disabled="cantAddIntegration"
            >{{ api.url }}</vs-dropdown-item
          >
        </vs-dropdown-menu>
      </vs-dropdown> -->

      <!-- no integrations available disclaimer -->
      <div class="disclaimer" v-if="showAvailabilityDisclaimer">
        <vs-icon color="warning" icon="warning"></vs-icon>
        <div>
          <span>
            {{
              lang.botMaker.integrations.disclaimer.notAvailable.prelink[
                languageSelected
              ]
            }}
            <a @click="goToIntegrations">
              {{
                lang.botMaker.integrations.disclaimer.notAvailable.link[
                  languageSelected
                ]
              }}
            </a>
            {{
              lang.botMaker.integrations.disclaimer.notAvailable.postlink[
                languageSelected
              ]
            }}
          </span>
        </div>
      </div>
    </div>

    <!-- current integrations -->
    <!-- <vs-list class="integrations-list">
      <vs-list-header
        :title="lang.botMaker.integrations.integratedApis[languageSelected]"
      ></vs-list-header>
      <vs-list-item
        v-for="(apiId, index) in selectedApis"
        :key="index"
        :title="getApiById(apiId)"
      >
        <vs-button
          color="primary"
          type="flat"
          icon="close"
          :title="lang.botMaker.integrations.popup.remove[languageSelected]"
          size="small"
          @click="removeIntegration(index)"
        ></vs-button>
      </vs-list-item>
    </vs-list> -->
    <!-- {{ selected }} -->

    <vs-table
      v-model="selected"
      :data="konaApiCalls"
      @selected="handleSelected"
    >
      <template slot="thead">
        <vs-th>
          URL
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].url">
            {{ data[indextr].url }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Integration',
  data() {
    return {
      jumpPopupActive: false,
      selectedApis: [],
      selectedNode: null,
      disableAddJump: true,
      selected: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'activeVersion',
      'userMessageInEdit',
      'botMessageInEdit',
      'botMakerMessages',
      'bot',
      'apiCalls',
      'dialogsMap'
    ]),
    cantAddIntegration() {
      return this.selectedApis.length > 0 || this.availableApis.length === 0
    },
    konaApiCalls() {
      return this.apiCalls.filter(ac => !ac.type || ac.type === '')
    },
    availableApis() {
      return this.konaApiCalls.filter(
        api =>
          !this.selectedApis.some(addedApi => {
            return addedApi === api._id
          })
      )
    },
    showAvailabilityDisclaimer() {
      return this.availableApis.length === 0 && !this.selectedApis.length > 0
    }
  },
  methods: {
    closeIntegrationsPopup() {
      this.selectedApis = []
      this.$emit('close', true)
    },
    goToIntegrations() {
      this.$emit('close', true)
      this.$nextTick(() => {
        this.$router.push({
          path: '/bot-maker/bot/' + this.bot.service.name + '/integrations',
          query: {
            service: this.bot.service.id,
            bot: this.bot.id,
            version: this.activeVersion
          }
        })
      })
    },
    openIntegrationsPopup() {
      // TODO: refactor
      // If the bot message is going to be the first message in the conversation
      if (this.botMakerMessages.length === 0) {
        // Show a dialog alerting the user that the first message should be an user message
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          acceptText: this.lang.botMaker.accept[this.languageSelected],
          cancelText: this.lang.botMaker.cancel[this.languageSelected],
          title: this.lang.botMaker.botEditor.botSays.emptyBotMakerMessages
            .title[this.languageSelected],
          text: this.lang.botMaker.botEditor.botSays.emptyBotMakerMessages
            .content[this.languageSelected]
        })

        return
      }

      const dialogId = this.userMessageInEdit.isEditing
        ? this.userMessageInEdit.dialogId
        : this.botMessageInEdit.dialogId

      const selectedApis = []
      if (
        dialogId &&
        this.dialogsMap[dialogId] &&
        this.dialogsMap[dialogId].apiCall
      ) {
        // currently only one integration is available, but will be multiple in the future
        const apiCalls = [].concat(this.dialogsMap[dialogId].apiCall)
        apiCalls.forEach(dialogApiCall => {
          const apiCall = this.apiCalls.filter(ac => ac._id === dialogApiCall)
          if (apiCall.length) {
            selectedApis.push(apiCall[0]._id)
          }
        })
      }
      this.selectedApis = selectedApis
      this.$emit('open', true)
    },
    // addIntegration(api) {
    //   this.selectedApis.push(api._id)
    //   this.$emit('addApi', api._id)
    // },
    // removeIntegration(index) {
    //   this.selectedApis.splice(index, 1)
    //   this.$emit('removeApi', index)
    // },
    getApiById(id) {
      const api = this.apiCalls.filter(a => a._id === id)
      return api[0].url || ''
    },
    handleSelected(tr) {
      this.$emit('onChange', [tr._id])
    }
  }
}
</script>
