var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"select-integration"},[(_vm.showAvailabilityDisclaimer)?_c('div',{staticClass:"disclaimer"},[_c('vs-icon',{attrs:{"color":"warning","icon":"warning"}}),_vm._v(" "),_c('div',[_c('span',[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.integrations.disclaimer.notAvailable.prelink[
              _vm.languageSelected
            ])+"\n          "),_c('a',{on:{"click":_vm.goToIntegrations}},[_vm._v("\n            "+_vm._s(_vm.lang.botMaker.integrations.disclaimer.notAvailable.link[
                _vm.languageSelected
              ])+"\n          ")]),_vm._v("\n          "+_vm._s(_vm.lang.botMaker.integrations.disclaimer.notAvailable.postlink[
              _vm.languageSelected
            ])+"\n        ")])])],1):_vm._e()]),_vm._v(" "),_c('vs-table',{attrs:{"data":_vm.konaApiCalls},on:{"selected":_vm.handleSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].url}},[_vm._v("\n          "+_vm._s(data[indextr].url)+"\n        ")])],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("\n        URL\n      ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }